import en from "./en-US.json";
import zhTW from "./zh-TW.json";

const resources = {
  "en": {
    translation: en,
  },
  "zh-TW": {
    translation: zhTW,
  },
};

export default resources;