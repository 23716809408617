import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  apiGetStatisicsData,
  apiCountryStatistics,
  apiVerifyRecaptchaAndSendMail,
  apiSendVerifyMail,
  apiCodeToVerifyEmail,
  apiUploadApk,
} from "../utils/api";
import "react-circular-progressbar/dist/styles.css";
import CustomBarChart from "../components/CustomBarChart";
// import GoTop from "../components/goTop.js";
import CircleComponent from "../components/circleComponent";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import CodeInput from "../components/CodeInput";

function IndexPage() {
  const { t, i18n } = useTranslation();
  const inputFile = useRef(null);
  const [isRedrawChart, setIsRedrawChart] = useState(false);
  const [isShowClose, setIsShowClose] = useState(true);
  const [isShowErrorToast, setIsShowErrorToast] = useState(false);
  const [isShowSucessModal, setIsShowSucessModal] = useState(false);
  const [isShowBackToStep1Modal, setIsShowBackToStep1Modal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [toastMsg, setToastMsg] = useState("");
  const [email, setEmail] = useState("");
  const [uploadFile, setUploadFile] = useState([]);
  const [onLocked, setOnLocked] = useState(false);
  const [, updateState] = useState();
  const fileMaximum = 500 * 1024 * 1024;
  const [isShowRecaptcha, setIsShowRecaptcha] = useState(true);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [apiRequestToken, setApiRequestToken] = useState(null);
  const recaptchaRef = useRef();
  const [emailComponentIn, setEmailComponentIn] = useState(1); // 0:往左滑動隱藏, 1:往左滑動顯示, 2:隱藏, 3:往右滑動顯示
  const [verifyComponentIn, setVerifyComponentIn] = useState(2); // 0:往左滑動隱藏, 1:往左滑動顯示, 2:隱藏, 3:往右滑動隱藏
  const [chooseFileComponentIn, setchooseFileComponentIn] = useState(2); // 1:往左滑動顯示, 2:隱藏
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [fileErrorMsg, setFileErrorMsg] = useState("");
  const [resendCount, setResendCount] = useState(0);
  const [vertifyCode, setVertifyCode] = useState("");
  const [vertifyCodeReset, setVertifyCodeReset] = useState(false);
  const [vertifyCodeErr, setVertifyCodeErr] = useState("");
  const [backToStep1Msg, setBackToStep1Msg] = useState("");
  const forceUpdate = useCallback(() => updateState({}), []);

  const handleChartMouseOver = () => {
    // reload chart
    setIsRedrawChart(true);
    setTimeout(() => {
      setIsRedrawChart(false);
    }, 500);
  };

  const handleUploadSuccessModal = () => {
    recaptchaRef.current.reset();
    setIsShowSucessModal(false);
    setEmailComponentIn(1);
    setVerifyComponentIn(2);
    setchooseFileComponentIn(2);
  };

  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const handleSecurityInfoReload = async () => {
    // reload security info
    if (!onLocked) {
      setOnLocked(true);
      var circle1 = securityCircle;
      setSecuritySircle({
        reverseEnginneringRisk: 0,
        confidentialDateRisk: 0,
        debuggerRisk: 0,
        integrityRisk: 0,
      });
      setTimeout(() => {
        setSecuritySircle(circle1);
        setOnLocked(false);
      }, 500);
    }
  };
  const [scanTotalChartData, setScanTotalChartData] = useState({
    datasets: [],
  });
  const [scanTotalChartOption, setScanTotalChartOption] = useState({});
  const [securityCircle, setSecuritySircle] = useState({
    reverseEnginneringRisk: 0,
    confidentialDateRisk: 0,
    debuggerRisk: 0,
    integrityRisk: 0,
  });

  const handleGoogleRecaptchaOnChange = (value) => {
    setRecaptchaToken(value);
  };

  const handleClickFileImage = (e) => {
    e.preventDefault();
    if (inputFile.current) {
      inputFile.current.click();
    }
    return false;
  };

  const handleUploadAPKVaild = (file) => {
    var fileNameList = file.name.split(".");
    var ext = fileNameList.length - 1;
    if (file.size > fileMaximum) {
      setFileErrorMsg(t("home.uploadAPK.error.fileSizeExceed"));
      return false;
    } else if (!(fileNameList[ext] === "apk" || fileNameList[ext] === "aab")) {
      setFileErrorMsg(t("home.uploadAPK.error.fileTypeWrong"));
      return false;
    } else {
      return true;
    }
  };

  const handleChangeFile = (e) => {
    var file = e.target.files;
    setUploadFile(file[0]);
    handleUploadAPKVaild(file[0]);
  };

  const handleDeleteFile = (e) => {
    e.preventDefault();
    setUploadFile([]);
  };

  const getGradient = (chart) => {
    const { ctx } = chart;
    const gradientSegment = ctx.createLinearGradient(0, 0, 0, 350);
    gradientSegment.addColorStop(0, "#f8f3d1");
    gradientSegment.addColorStop(1, "#c8a32a");
    return gradientSegment;
  };

  const handleEmailInputChange = (e) => {
    var value = e.target.value;
    setEmail(value);
  };

  const handleSendEmail = () => {
    if (email === "") {
      setEmailErrorMsg(t("home.uploadAPK.error.nullEmail"));
      return;
    }

    if (!isEmail(email)) {
      setEmailErrorMsg(t("home.uploadAPK.error.emailFormat"));
      return;
    }

    if (!recaptchaToken) {
      setEmailErrorMsg(t("home.uploadAPK.error.notRobot"));
      return;
    }
    setVertifyCodeReset(true);
    setVertifyCodeErr("");
    setIsLoading(true);
    callRecaptchaAndSendMailApi();
  };

  const handleToast = (msg) => {
    setIsShowErrorToast(true);
    setToastMsg(msg);
    setTimeout(() => {
      setIsShowErrorToast(false);
      setToastMsg("");
    }, 5000);
  };

  useEffect(() => {
    getCountryStatistics(country.tw);
    const fetchData = async () => {
      var res = await apiGetStatisicsData();
      var data = res.result;
      var statistics = res.result.totalStatistics;
      var legend = data.month;
      var option = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          backgroundColor: "#F6F6F6",
        },
      };
      var circle = securityCircle;
      Object.keys(statistics).forEach((key) => {
        switch (key) {
          case "reverseEnginneringRisk": {
            circle.reverseEnginneringRisk = statistics[key];
            break;
          }
          case "confidentialDateRisk": {
            circle.confidentialDateRisk = statistics[key];
            break;
          }
          case "debuggerRisk": {
            circle.debuggerRisk = statistics[key];
            break;
          }
          case "integrityRisk": {
            circle.integrityRisk = statistics[key];
            break;
          }
          default:
            break;
        }
      });
      var chartData = {
        labels: legend,
        datasets: [
          {
            id: 1,
            label: "",
            data: data.monthAmount,
            borderColor: "#c8a32a",
            backgroundColor: (context) => {
              const chart = context.chart;
              const { chartArea } = chart;
              if (!chartArea) {
                return null;
              }
              return getGradient(chart);
            },
          },
        ],
      };
      setSecuritySircle(circle);
      setScanTotalChartData(chartData);
      setScanTotalChartOption(option);
    };
    fetchData();
    handleChartMouseOver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUploadFile = () => {
    if (uploadFile.length === 0) {
      setFileErrorMsg(t("home.uploadAPK.error.nullFile"));
    } else if (!isUploading) {
      var isFileVaild = handleUploadAPKVaild(uploadFile);
      if (isFileVaild) {
        setIsUploading(true);
        callUploadApkApi();
      }
    }
  };

  useEffect(() => {
    forceUpdate();
    formatPieData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const country = {
    tw: 1,
    jp: 2,
    kr: 3,
    hk: 4,
    sg: 5,
    my: 6,
    th: 7,
    vn: 8,
  };
  const [countyStatistics, setCountyStatistics] = useState([]);
  const [countyStatApiData, setCountyStatApiData] = useState([]);
  const [activeCountry, setActiveCountry] = useState(country.tw);
  const countryImages = [
    { id: country.tw, src: "/images/taiwan.png", label: t("home.country.tw") },
    { id: country.jp, src: "/images/japan.png", label: t("home.country.jp") },
    {
      id: country.kr,
      src: "/images/south-korea.png",
      label: t("home.country.kr"),
    },
    {
      id: country.hk,
      src: "/images/hong-kong.png",
      label: t("home.country.hk"),
    },
    {
      id: country.sg,
      src: "/images/singapore.png",
      label: t("home.country.sg"),
    },
    { id: country.my, src: "/images/malasya.png", label: t("home.country.my") },
    {
      id: country.th,
      src: "/images/thailand.png",
      label: t("home.country.th"),
    },
    { id: country.vn, src: "/images/vietnam.png", label: t("home.country.vn") },
  ];
  const chartColors = ["#FFB944", "#22C79B", "#5378C8", "#FB6F64"];
  const chartOptions = {
    animation: true,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "rect",
        },
      },
      datalabels: {
        // 設定圖表上文字的樣式
        display: true,
        color: "white",
        align: "center",
        textAlign: "center",
        textBaseline: "middle",
        font: {
          size: 16,
        },
        formatter: function (value, context) {
          return context.dataset.data[context.dataIndex] > 5
            ? `${value.toFixed(0)}%`
            : value;
        },
      },
    },
    maintainAspectRatio: false,
  };

  const getCountryStatistics = async (country) => {
    try {
      var res = await apiCountryStatistics(country);
      if (res.code === "0000") {
        var data = res.result;
        setCountyStatApiData(data);
      } else {
        handleToast(
          t("home.error.apiError", {
            message: res.message,
          })
        );
      }
    } catch (e) {
      handleToast(
        t("home.error.apiError", {
          message: res.message,
        })
      );
    }
  };

  const formatPieData = () => {
    const formattedData = countyStatApiData.map((item) => {
      let text = "";
      switch (item.class) {
        case 1:
          text = t("home.country.app.game");
          break;
        case 2:
          text = t("home.country.app.finance");
          break;
        case 3:
          text = t("home.country.app.comm");
          break;
        case 4:
          text = t("home.country.app.shopping");
          break;
        default:
          break;
      }
      return {
        class: item.class,
        text: text,
        data: [
          item.reverseEnginneringRisk,
          item.debuggerRisk,
          item.integrityRisk,
          item.confidentialDateRisk,
        ],
      };
    });
    formattedData.sort((a, b) => a.class - b.class);
    setCountyStatistics(formattedData);
  };

  useEffect(() => {
    formatPieData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countyStatApiData]);

  const generateChartBlock = (classNumber, index) => {
    const selectedData = countyStatistics.find(
      (item) => item.class === classNumber
    );
    const isNoData =
      selectedData && selectedData.data.every((value) => value === -1);
    if (isNoData) {
      return (
        <div key={index} className="col-md-6">
          <div className="trapezoid-container">
            {selectedData && (
              <div className="trapezoid-text">
                {t("home.country.app.title", {
                  country: countryImages.find(
                    (country) => country.id === activeCountry
                  )?.label,
                  class: selectedData.text,
                })}
              </div>
            )}
            <div className="inverted-trapezoid" />
          </div>
          <div id={`noDataPieChart`}>
            <Pie
              data={{
                labels: ["Coming soon"],
                datasets: [
                  {
                    data: [100],
                    backgroundColor: [chartColors[3]],
                    hoverBackgroundColor: [chartColors[3]],
                    borderWidth: 0,
                  },
                ],
              }}
              options={{
                plugins: {
                  tooltip: {
                    enabled: false,
                  },
                  legend: {
                    display: false,
                    labels: {
                      display: false,
                    },
                  },
                  datalabels: { display: false },
                },
                animation: true,
                responsive: true,

                maintainAspectRatio: false,
              }}
            />
            <div className="middle-text">Coming soon</div>
          </div>
        </div>
      );
    }
    return (
      <div key={index} className="col-md-6">
        <div className="trapezoid-container">
          {selectedData && (
            <div className="trapezoid-text">
              {t("home.country.app.title", {
                country: countryImages.find(
                  (country) => country.id === activeCountry
                )?.label,
                class: selectedData.text,
              })}
            </div>
          )}
          <div className="inverted-trapezoid" />
        </div>
        <div id={`pieChart`}>
          {selectedData && (
            <Pie
              data={{
                labels: [
                  t("home.country.label.reverseEnginneringRisk"),
                  t("home.country.label.debuggerRisk"),
                  t("home.country.label.integrityRisk"),
                  t("home.country.label.confidentialDateRisk"),
                ],
                datasets: [
                  {
                    borderColor: "#FAF9F9",
                    borderWidth: 2,
                    data: selectedData.data,
                    backgroundColor: chartColors,
                    hoverBackgroundColor: chartColors,
                  },
                ],
              }}
              options={chartOptions}
              plugins={[ChartDataLabels]}
            />
          )}
        </div>
      </div>
    );
  };

  const handleCountryClick = async (countryId) => {
    setActiveCountry(countryId);
    await getCountryStatistics(countryId);
  };

  const handleVertifyCodeChange = (value) => {
    setVertifyCode(value);
  };

  // 驗證碼為6碼時call API驗證
  useEffect(() => {
    if (vertifyCode.length === 6) {
      callCodeToVerifyEmailApi();
    } else if (vertifyCode.length === 0) {
      setVertifyCodeErr("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vertifyCode]);

  // 重送驗證碼倒數計時器
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (resendCount > 0) {
        setResendCount(resendCount - 1);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [resendCount]);

  // email輸入元件
  const emailInputComponent = () => {
    return (
      <div
        className={`slide-transition ${
          emailComponentIn === 1
            ? "show0"
            : emailComponentIn === 2
            ? "isTransparent"
            : emailComponentIn === 3
            ? "show0ToRight"
            : "out"
        }`}
      >
        <div className="emailInputComponent">
          {emailErrorMsg !== "" && <div className="error">{emailErrorMsg}</div>}
          <h4>{t("home.blackbox.emailPlacehold")}</h4>
          <div className="input-group ">
            <input
              id="email"
              type="email"
              className="form-control"
              placeholder="abcd1234@example.com"
              value={email}
              onChange={handleEmailInputChange}
            />
            <input
              type="button"
              className={`${isLoading && "disable"}`}
              value="GO"
              onClick={handleSendEmail}
              disabled={isLoading}
            />
          </div>
          {isShowRecaptcha && (
            <ReCAPTCHA
              ref={recaptchaRef}
              size="normal"
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              className="g-recaptcha"
              hl={i18n.language}
              onChange={handleGoogleRecaptchaOnChange}
            />
          )}
        </div>
      </div>
    );
  };

  // 驗證碼元件
  const verificationComponent = () => {
    return (
      <div
        className={`slide-transition ${
          verifyComponentIn === 1
            ? "show1"
            : verifyComponentIn === 2
            ? "isTransparent"
            : verifyComponentIn === 3
            ? "out1ToRight"
            : "out1"
        }`}
      >
        <div className="verificationComponent">
          {vertifyCodeErr !== "" && (
            <div className="error">{vertifyCodeErr}</div>
          )}
          <h4>
            {t("home.blackbox.verify.input")}
            <span> {email}</span>
            <br />
            {t("home.blackbox.verify.10min")}
          </h4>
          <CodeInput
            onChange={handleVertifyCodeChange}
            reset={vertifyCodeReset}
            vertifyCodeErr={vertifyCodeErr}
          />

          <button
            className={`resend ${resendCount > 0 && "disable"}`}
            disabled={resendCount > 0}
            onClick={() => {
              // 重新發送驗證信
              setResendCount(60);
              setVertifyCodeReset(true);
              setVertifyCodeErr("");
              callSendMailApi();
            }}
          >
            {resendCount === 0
              ? t("home.blackbox.verify.resend")
              : t("home.blackbox.verify.resendSec", {
                  sec: resendCount,
                })}
          </button>

          <div className="cantFindMail">
            <div>
              {t("home.blackbox.verify.cantFindIt")}
              <span
                onClick={() => {
                  // 嘗試其他信箱
                  setEmailComponentIn(3);
                  setVerifyComponentIn(3);
                  setchooseFileComponentIn(2);
                  setVertifyCodeReset(true);
                  setVertifyCodeErr("");
                  setIsShowRecaptcha(true);
                }}
              >
                {t("home.blackbox.verify.anotherMail")}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 上傳檔案元件
  const chooseFileComponent = () => {
    return (
      <div
        className={`slide-transition ${
          chooseFileComponentIn === 1 ? "show2" : "isTransparent"
        }`}
      >
        <div className="chooseFileComponent">
          {fileErrorMsg !== "" && <div className="error">{fileErrorMsg}</div>}
          <h3 className="pb_2">{t("home.blackbox.chooseFile")}</h3>
          <h6 className="hint">{t("home.blackbox.fileLimit")}</h6>
          <div className="inputBox">
            <input
              type="file"
              id="apk_file"
              className="form-control d-none"
              ref={inputFile}
              accept=".apk, .aab"
              value=""
              onChange={handleChangeFile}
            />
            <img
              className="choseBtn"
              alt="choosebtn"
              src="/images/choose-bnt.png"
              onClick={handleClickFileImage}
            />
            {uploadFile.length !== 0 && (
              <div className="uploadFileBox justify-content-center d-flex align-items-center">
                <span className="ellipsis1">
                  {uploadProgress > 0
                    ? `Uploading ${uploadProgress} % `
                    : uploadFile.name}
                </span>
                {isShowClose && (
                  <img
                    className="close-btn"
                    alt="choosebtn"
                    src="/images/ic_close.png"
                    onClick={handleDeleteFile}
                  />
                )}
              </div>
            )}

            <button
              className="goBtn"
              disabled={isUploading}
              onClick={handleUploadFile}
            >
              GO
            </button>
          </div>
        </div>
      </div>
    );
  };

  // API編號1.2 - 驗證google recaptcha & 寄送mail
  const callRecaptchaAndSendMailApi = async () => {
    setEmailErrorMsg("");
    var requestData = {
      Email: email,
      Lang: i18n.language,
      RequestIp: null,
      // RequestIp: "211.21.7.43",
      RecaptchaToken: recaptchaToken,
    };
    try {
      var apiRes = await apiVerifyRecaptchaAndSendMail(requestData);
      if (apiRes && apiRes.code === "0000") {
        setIsLoading(false);
        setEmailComponentIn(0);
        setVerifyComponentIn(1);
        setchooseFileComponentIn(2);
        setResendCount(60);
        setVertifyCodeReset(false);
        setApiRequestToken(apiRes.result.RequestToken);
        setEmailErrorMsg("");
        setIsShowRecaptcha(false);
        setVertifyCodeErr("");
      } else {
        setIsLoading(false);
        if (apiRes.code === "9004") {
          // 驗證次數過於頻繁，請稍後再試
          setBackToStep1Msg(t("home.uploadAPK.error.tooFrequent"));
          setIsShowBackToStep1Modal(true);
        } else if (apiRes.code === "9005") {
          // 信箱格式錯誤
          setEmailErrorMsg(t("home.uploadAPK.error.emailFormat"));
        } else if (apiRes.code === "9008") {
          // 驗證流程已逾時，請重新再試，謝謝
          setBackToStep1Msg(t("home.blackbox.verify.expired"));
          setIsShowBackToStep1Modal(true);
        } else {
          handleToast(
            t("home.error.apiError", {
              message: apiRes.message,
            })
          );
        }
        recaptchaRef.current.reset();
        setRecaptchaToken("");
      }
    } catch (e) {
      handleToast(
        t("home.error.apiError", {
          message: e.message,
        })
      );
    }
  };

  // API編號1.3 - 發送驗證信(無Recaptcha)
  const callSendMailApi = async () => {
    var requestData = {
      Email: email,
      Lang: i18n.language,
      RequestIp: null,
      // RequestIp: "211.21.7.43",
      RequestToken: apiRequestToken,
    };
    try {
      var apiRes = await apiSendVerifyMail(requestData);
      if (apiRes && apiRes.code === "0000") {
        setApiRequestToken(apiRes.result.RequestToken);
        setVertifyCodeReset(false);
      } else {
        if (apiRes.code === "9004") {
          // 驗證次數過於頻繁，請稍後再試
          setBackToStep1Msg(t("home.uploadAPK.error.tooFrequent"));
          setIsShowBackToStep1Modal(true);
        } else if (apiRes.code === "9005") {
          // 信箱格式錯誤
          setEmailErrorMsg(t("home.uploadAPK.error.emailFormat"));
        } else if (apiRes.code === "9008") {
          // 驗證流程已逾時，請重新再試，謝謝
          setBackToStep1Msg(t("home.blackbox.verify.expired"));
          setIsShowBackToStep1Modal(true);
        } else if (apiRes.code === "9010") {
          // 寄送驗證信間格過短，請稍後再試
          setVertifyCodeErr(t("home.blackbox.sendMail.tooFrequent"));
        } else {
          handleToast(
            t("home.error.apiError", {
              message: apiRes.message,
            })
          );
        }
      }
    } catch (e) {
      handleToast(
        t("home.error.apiError", {
          message: e.message,
        })
      );
    }
  };

  // API編號1.4 - 驗證信箱驗證碼
  const callCodeToVerifyEmailApi = async () => {
    var requestData = {
      VerifyCode: vertifyCode,
      RequestToken: apiRequestToken,
    };
    try {
      var apiRes = await apiCodeToVerifyEmail(requestData);
      if (apiRes && apiRes.code === "0000") {
        setVertifyCodeErr("");
        setFileErrorMsg("");
        setEmailComponentIn(2);
        setVerifyComponentIn(0);
        setchooseFileComponentIn(1);
        setVertifyCodeReset(true);
      } else {
        if (apiRes.code === "9007") {
          // 輸入錯誤，請重新輸入
          setVertifyCodeErr(t("home.blackbox.verify.error"));
        } else if (apiRes.code === "9008") {
          // 驗證流程已逾時，請重新再試，謝謝
          setBackToStep1Msg(t("home.blackbox.verify.expired"));
          setIsShowBackToStep1Modal(true);
        } else {
          handleToast(
            t("home.error.apiError", {
              message: apiRes.message,
            })
          );
        }
      }
    } catch (e) {
      handleToast(
        t("home.error.apiError", {
          message: e.message,
        })
      );
    }
  };

  // API編號1.5 - 上傳檔案
  const callUploadApkApi = async () => {
    try {
      setFileErrorMsg("");
      let formData = new FormData();
      var uploadData = {
        File: uploadFile,
        RequestToken: apiRequestToken,
        FileType: 1,
        ActionType: 1,
      };
      Object.keys(uploadData).forEach((key) => {
        formData.append(key, uploadData[key]);
      });
      var apiRes = await apiUploadApk(formData, (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded / total) * 100) - 1;
        // console.log(`percent: ${percent}`);
        setUploadProgress(percent > 0 ? percent : 0);
        setIsShowClose(false);
      });
      if (apiRes && apiRes.code === "0000") {
        setVertifyCodeReset(true);
        setVertifyCodeErr("");
        setIsShowSucessModal(true);
        setUploadFile([]);
        setUploadProgress(0);
        setIsShowClose(true);
        setEmail("");
        setIsUploading(false);
        setRecaptchaToken("");
        setApiRequestToken(null);
        setEmailComponentIn(2);
        setVerifyComponentIn(0);
        setchooseFileComponentIn(1);
        setIsShowRecaptcha(true);
      } else {
        // 上傳檔案失敗
        if (apiRes.code === "9003") {
          // 由於目前測試用戶較多，請稍後再試。 感謝您的體諒。
          handleToast(t("home.uploadAPK.error.machineIsBusy"));
        } else if (apiRes.code === "9006") {
          // 檢測檔案必須為 APK/AAB檔
          setFileErrorMsg(t("home.uploadAPK.error.fileTypeWrong"));
        } else if (apiRes.code === "9009") {
          // 檔案大小須小於500MB
          setFileErrorMsg(t("home.uploadAPK.error.fileSizeExceed"));
        } else if (apiRes.code === "9012") {
          // 操作已逾時，請重新再試。
          setBackToStep1Msg(t("home.uploadAPK.error.timeout"));
          setIsShowBackToStep1Modal(true);
        } else {
          // 上傳失敗，請重新再試
          setFileErrorMsg(t("home.uploadAPK.error.default"));
        }
        setUploadProgress(0);
        setIsUploading(false);
        setIsShowClose(true);
      }
    } catch (e) {
      handleToast(
        t("home.error.apiError", {
          message: e.message,
        })
      );
    }
  };

  // 流程逾時/驗證過於頻繁的modal的按鈕
  const handleTimeoutModalBtn = () => {
    setIsShowBackToStep1Modal(false);
    setEmail("");
    setIsShowRecaptcha(true);
    setUploadFile([]);
    setUploadProgress(0);
    setIsShowClose(true);
    setIsUploading(false);
    setFileErrorMsg("");
    setEmailComponentIn(1);
    setVerifyComponentIn(2);
    setchooseFileComponentIn(2);
  };

  return (
    <>
      <main>
        <section id="black-box">
          <div className="container">
            <div className="title-box mb-3">
              <h1 className="fs-2 text-center lh-sm">
                {t("home.blackbox.title")}
              </h1>
            </div>
            <div
              className="center-container gray-background col-lg-9 justify-content-center d-flex align-items-center"
              id="inputContainer"
            >
              <div className="upload justify-content-center d-flex flex-column align-items-center">
                <div className="slide-container">
                  {emailInputComponent()}
                  {verificationComponent()}
                  {chooseFileComponent()}
                </div>
                <div className="flow">
                  <img
                    src={`/images/${
                      i18n.language === "zh-TW" ? "flow_tw" : "flow_en"
                    }.png`}
                    alt="flow"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="scan-total-chart">
          <div className="container">
            <div className="title-box mb-3">
              <h1 className="fs-2 text-center lh-sm">
                {t("home.scanTotalChart.title")}
              </h1>
            </div>
            <div
              onMouseOver={handleChartMouseOver}
              className="center-container gray-background col-lg-10 justify-content-center d-flex align-items-center"
            >
              {/* <canvas id="myChart"> */}
              {/* <Barchart /> */}
              <div
                style={{ height: "280px", width: "100%", position: "relative" }}
              >
                <CustomBarChart
                  id="customBarchart"
                  chartData={scanTotalChartData}
                  options={scanTotalChartOption}
                  redraw={isRedrawChart}
                />
              </div>
              {/* </canvas> */}
            </div>
          </div>
        </section>
        <section id="security-info">
          <div className="container">
            <div className="title-box mb-3">
              <h1 className="fs-2 text-center lh-sm">
                {t("home.securityInfo.title")}
              </h1>
            </div>
            <div className="center-container col-lg-10 circle-progress-group">
              <div className="row">
                <CircleComponent
                  onHover={handleSecurityInfoReload}
                  index={0}
                  id={"integrityRisk"}
                  progress={securityCircle.integrityRisk}
                />
                <CircleComponent
                  onHover={handleSecurityInfoReload}
                  index={1}
                  id={"reverseEnginneringRisk"}
                  progress={securityCircle.reverseEnginneringRisk}
                />
                <CircleComponent
                  onHover={handleSecurityInfoReload}
                  index={2}
                  id={"confidentialDateRisk"}
                  progress={securityCircle.confidentialDateRisk}
                />
                <CircleComponent
                  onHover={handleSecurityInfoReload}
                  index={3}
                  id={"debuggerRisk"}
                  progress={securityCircle.debuggerRisk}
                />
              </div>
            </div>
          </div>
        </section>
        <section id="country">
          <div className="container">
            <div className="center-container col-lg-8">
              <div className="row">
                {countryImages.map((image, index) => (
                  <div
                    key={index}
                    className={`position-relative col-md-3 px-4 py-3 ${
                      image.id === activeCountry ? "active" : ""
                    }`}
                    onClick={() => handleCountryClick(image.id)}
                  >
                    <img
                      src={image.src}
                      alt={image.label}
                      className={image.id !== activeCountry ? "overlay" : ""}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="pieContainerStyle">
            <div className="container">
              <div className="row">
                {countyStatistics.map((data, index) =>
                  generateChartBlock(data.class, index)
                )}
              </div>
            </div>
          </div>
        </section>
        <section id="promote">
          <div className="container">
            <div className="center-container col-lg-8">
              <div className="row">
                <div className="position-relative col-md-4">
                  <img
                    src={`/images/${
                      i18n.language === "zh-TW" ? "f-1" : "01"
                    }.png`}
                    alt="promo1"
                  />
                </div>
                <div className="position-relative col-md-4">
                  <img
                    src={`/images/${
                      i18n.language === "zh-TW" ? "f-2" : "02"
                    }.png`}
                    alt="promo2"
                  />
                </div>
                <div className="position-relative col-md-4">
                  <img
                    src={`/images/${
                      i18n.language === "zh-TW" ? "f-3" : "03"
                    }.png`}
                    alt="promo3"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="d-none d-md-block unood_ad_space_pc"></div>
        <div
          className={isShowSucessModal ? "modal small active" : "modal small"}
        >
          <div className="modal-container">
            <div className="modal-body">
              <div className="text-holder">
                {t("home.uploadAPK.success.hint")}
              </div>
              <div className="btn-holder">
                <button className="btn" onClick={handleUploadSuccessModal}>
                  {t("home.uploadAPK.success.confirm")}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <GoTop /> */}
        <div
          className={
            isShowBackToStep1Modal ? "modal small active" : "modal small"
          }
        >
          <div className="modal-container">
            <div className="modal-body">
              <div className="text-holder">{backToStep1Msg}</div>
              <div className="btn-holder">
                <button className="btn" onClick={handleTimeoutModalBtn}>
                  {t("home.uploadAPK.success.confirm")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      {isShowErrorToast && (
        <div class={"toast show error"}>
          <strong className="mr-auto">{toastMsg}</strong>
        </div>
      )}
    </>
  );
}
export default IndexPage;
