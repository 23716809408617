import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
function Header() {
  const [isShowDrop, setIsShowDrop] = useState(false);
  const [path, setPath] = useState("");
  const { i18n } = useTranslation();
  var isMenu = false;
  var isSubMenu = false;
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split("/");
    if (path[1] === "zh-TW" || path[1] === "en") {
      if (path.length === 3) {
        setPath(`/${path[2]}`);
      } else {
        setPath("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMouseEnter = () => {
    isMenu = true;
    setIsShowDrop(true);
  };

  const handleMouseLeave = () => {
    isMenu = false;
    setTimeout(handleCloseDropDown, 100);
  };

  const handleDropDownMouseEnter = () => {
    isSubMenu = true;
  };

  const handleDropDownMouseLeave = () => {
    isSubMenu = false;
    setTimeout(handleCloseDropDown, 100);
  };

  const handleCloseDropDown = () => {
    if (!isMenu && !isSubMenu) {
      setIsShowDrop(false);
    }
  };

  return (
    <header id="main-navbar" className="fixed-top">
      <div className="line line-top"></div>
      <nav className="navbar navbar-expand-lg navbar-light bg-white p-0">
        <div className="container">
          <a className="navbar-brand" href={`/${i18n.language}`}>
            <img className="logo" alt="logo" src="/images/logo.svg" />
          </a>
          <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li
                className="nav-item"
                onMouseOver={handleMouseEnter}
                onMouseOut={handleMouseLeave}
              >
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                <a
                  className="nav-link px-3"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  target="_self"
                >
                  <span className="link-name ">About</span>
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link px-3"
                  href={`/${i18n.language}/contact`}
                  target="_self"
                >
                  <span className="link-name ">Contact</span>
                </a>
              </li>
              {i18n.language === "zh-TW" ? (
                <li className="nav-item">
                  <a className="nav-link px-3" href={`/en${path}`}>
                    <span className="link-name ">English</span>
                  </a>
                </li>
              ) : (
                <li className="nav-item">
                  <a className="nav-link px-3" href={`/zh-TW${path}`}>
                    <span className="link-name ">中文版</span>
                  </a>
                </li>
              )}
              {isShowDrop && (
                <nav
                  className="nav-dropdown"
                  onMouseOver={handleDropDownMouseEnter}
                  onMouseLeave={handleDropDownMouseLeave}
                >
                  <div className="container">
                    <ul className="d-flex flex-column flex-lg-row justify-content-end">
                      {/* // style="padding-right: 97px;" */}
                      <li>
                        <a
                          href={`/${i18n.language}/aboutUs`}
                          className="nav-link px-3"
                        >
                          HyperG
                        </a>
                      </li>
                      <li>
                        <a
                          href={`/${i18n.language}/aboutATG`}
                          className="nav-link px-3"
                        >
                          AppTotalGo
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              )}
            </ul>
          </div>
        </div>
      </nav>
      <div className="line line-bottom"></div>
    </header>
  );
}
export default Header;
