import Header from "./Header";
import Footer from "./Footer";
import i18n from "../i18n/i18n";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

function Home(props) {
    const { lang } = useParams();
    useEffect(() => {
        if (lang === "zh-TW" || lang === "en") {
            toggleLanguage(lang)
        } else {
            window.location.href = '/zh-TW'
        }
    }, [lang])

    const toggleLanguage = (newLanguage) => {
        if (newLanguage !== i18n.language) {
            // 若 URL 語系與當前語系不同，則轉換語系
            i18n.changeLanguage(newLanguage);
        }
    }
    return (<>
        <Header />
        <div id="appTotalGo" className="page-content">
            {props.children}
            <div className="popup-loading">
                <div className="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
        <Footer />
    </>
    );
}
export default Home;