import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./i18n/i18n.js";
// import './Apptotalgo.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Home from "./components/Home.js";
import IndexPage from "./page/index.js";
import AboutUs from "./page/aboutUs.js";
import Contact from "./page/contact.js";
import AboutATG from "./page/aboutATG.js";

function App() {
  // const { t, i18n } = useTranslation();

  // function changeLanguageOnClick(language) {
  //   i18n.changeLanguage(language);
  //   document.cookie = "lang=" + i18n.language; // 將選擇之語言存進Cookie
  //   // console.log('切換語言了,目前cookie內容：'+document.cookie);

  // }

  // document.cookie = "lang=" + i18n.language; // 將預設語言存進Cookie

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/zh-TW" />} />
        <Route path="/:lang/">
          <Route
            index
            path=""
            element={
              <Home>
                <IndexPage />
              </Home>
            }
          />
          <Route
            path="aboutUs"
            element={
              <Home>
                <AboutUs />
              </Home>
            }
          />
          <Route
            path="contact"
            element={
              <Home>
                <Contact />
              </Home>
            }
          />
          <Route
            path="aboutATG"
            element={
              <Home>
                <AboutATG />
              </Home>
            }
          />
        </Route>
        {/* <Route path="/aboutUs"
          element={
            <Home>
              <AboutUs />
            </Home>
          } /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
