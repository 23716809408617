import React, { useState, useEffect } from "react";
import "../Apptotalgo.css";

const CodeInput = ({ onChange, reset, vertifyCodeErr }) => {
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const inputRefs = Array(6)
    .fill(0)
    .map(() => React.createRef());

  const handleInputChange = (e, index) => {
    const value = e.target.value;

    if (/^\d?$/.test(value)) {
      setCode((prevCode) => {
        const newCode = [...prevCode];
        newCode[index] = value;
        return newCode;
      });

      if (value !== "" && index < 5) {
        const nextInputRef = inputRefs[index + 1];
        nextInputRef.current.focus();
        nextInputRef.current.select();
      }

      onChange(
        [...code.slice(0, index), value, ...code.slice(index + 1)].join("")
      );
    }
  };

  useEffect(() => {
    if (reset) {
      setCode(["", "", "", "", "", ""]);
      onChange("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && code[index] === "") {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleInputClick = (index) => {
    inputRefs[index].current.select();
  };

  const handleVerificationCodeErrClick = () => {
    if (vertifyCodeErr !== "") {
      setCode(["", "", "", "", "", ""]);
      onChange("");
      inputRefs[0].current.focus();
    }
  };

  return (
    <div
      className="verification-code-input"
      onClick={handleVerificationCodeErrClick}
    >
      {code.map((digit, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={digit}
          onChange={(e) => handleInputChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onClick={() => handleInputClick(index)}
          ref={inputRefs[index]}
        />
      ))}
    </div>
  );
};

export default CodeInput;
