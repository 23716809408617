import { useTranslation } from "react-i18next";
import GoTop from "../components/goTop";

function AboutUs() {
  const { t, i18n } = useTranslation();

  return (
    <div id="about">
      <div className="container">
        <div className="mb-5">
          <h1 className="about-title">{t("about.us.title")}</h1>
          <p className="contents about-content mb-4">{t("about.us.content")}</p>
          {i18n.language === "zh-TW" && (
            <p className="contents about-foreground">
              {t("about.us.foreground")}
            </p>
          )}

          <img
            className="w-100 my-3"
            src="/images/comlogo.png"
            alt="company logo"
          ></img>
        </div>

        <div className="mb-5">
          <h1 className="about-title">{t("about.us.philosophy")}</h1>
          <p className="contents about-content mb-4">
            {t("about.us.phil.content")}
          </p>
          <ul className="list">
            <li>{t("about.us.phil.security")}</li>
            <li>{t("about.us.phil.honest")}</li>
            <li>{t("about.us.phil.staff")}</li>
            <li>{t("about.us.phil.partner")}</li>
            <li>{t("about.us.phil.customer")}</li>
            <li>{t("about.us.phil.quality")}</li>
          </ul>
        </div>

        <div className="ratio ratio-16x9 bg-dark">
          <iframe
            width="950"
            height="534"
            src="https://www.youtube.com/embed/tpmUi1Tj9Ws"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <GoTop />
    </div>
  );
}
export default AboutUs;
