import { useTranslation } from "react-i18next";
import GoTop from "../components/goTop";
import i18n from "../i18n/i18n";

function AboutATG() {
    const { t } = useTranslation();
    return (
        <main>
            <section id="appTotalGo">
                <div className="container">
                    <div className="title-box mb-3">
                        <h1 className="fs-2 text-center lh-sm">{t("aboutus.apptotalgo.title")}</h1>
                    </div>
                    <div className="center-container gray-background col-lg-8 justify-content-center d-flex align-items-center">
                        <div className="upload justify-content-center d-flex  align-items-center">
                            <img style={{ width: "110px" }} src="/images/seo.png" alt="seo" />
                            <div style={{ width: "32px" }}></div>
                            <div >
                                *Mobile Application Security (MAS)<br />
                                *OWASP Security Checklist L2<br />
                                *OWASP Mobile Risk Top 10<br />
                                *GDPR<br />
                                *NIAP Protection Profile for Application Software<br />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section id="check-flow" className="page-section">
                <div className="container">
                    <div className="title-box mb-5">
                        <h1 className="fs-2 text-center lh-sm">
                            {t("aboutus.apptotalgo.process.title")}
                        </h1>
                        <img className="title-underline" src="/images/title-underline.png" alt="" />
                    </div>

                    <div className="list d-flex pt-4">
                        {[1, 2, 3].map((e) => {
                            return (<div className="list-item">
                                <img className="item-pic mb-4" src={`/images/flow-${e}.png`} alt="" />
                                <h2 className="item-title text-center lh-base">{t(`aboutus.apptotalgo.process.step${e}`)}</h2>
                            </div>);
                        })}
                    </div>
                </div>
            </section>
            <section id="check-tools" className="page-section">
                <div className="container">

                    <div className="pb-3 pb-md-5">
                        <div className="title-box mb-5">
                            <h1 className="fs-2 text-center lh-sm">
                                {t("aboutus.apptotalgo.detection.dynamic.title")}
                            </h1>

                            <img className="title-underline" src="{{url('/assets/img/app-total-go/title-underline.png')}}" alt="" />
                        </div>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-center bg-primary text-white" scope="col">{t("aboutus.apptotalgo.detection.col1.title")}</th>
                                    <th className="text-center bg-primary text-white" scope="col">{t("aboutus.apptotalgo.detection.col2.title")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="bg-white p-3 fs-5 text-center align-middle">IDA Pro</td>
                                    <td className="bg-white p-3">
                                        <ul className="center-ul">
                                            {[1, 2, 3, 4, 5].map((e) => {
                                                return <li>{t(`aboutus.apptotalgo.detection.dynamic.ida.result${e}`)}</li>
                                            })}
                                            {/* <li>1.原始碼保護效果及漏洞分析(截圖報告)</li>
                                            <li>2.防記憶體偵測效果及漏洞分析(截圖報告)</li>
                                            <li>3.完成性校驗強度驗證(截圖報告)</li>
                                            <li>4.是否可在登入帳號後進行植入攻擊及竄改</li>
                                            <li>5.依據測試結果產出動態影片</li> */}
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bg-white p-3 fs-5 text-center align-middle">Frida</td>
                                    <td className="bg-white p-3">
                                        <ul className="center-ul">
                                            {[1, 2, 3, 4, 5].map((e) => {
                                                return <li>{t(`aboutus.apptotalgo.detection.dynamic.frida.result${e}`)}</li>
                                            })}
                                            {/* <li>1.原始碼保護效果及漏洞分析(截圖報告)
                                            </li>
                                            <li>2.防記憶體偵測效果及漏洞分析(截圖報告)
                                            </li>
                                            <li>3.完成性校驗強度驗證(截圖報告)
                                            </li>
                                            <li>4.是否可在登入帳號後進行植入攻擊及竄改
                                            </li>
                                            <li>5.依據測試結果產出動態影片</li> */}
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="pt-3 pt-mb-5">
                        <div className="title-box mb-5">
                            <h1 className="fs-2 text-center lh-sm">
                                {t("aboutus.apptotalgo.detection.static.title")}
                            </h1>

                            <img className="title-underline" src="{{url('/assets/img/app-total-go/title-underline.png')}}" alt="" />
                        </div>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-center bg-primary text-white" scope="col">{t("aboutus.apptotalgo.detection.col1.title")}</th>
                                    <th className="text-center bg-primary text-white" scope="col">{t("aboutus.apptotalgo.detection.col2.title")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="bg-white p-3 fs-5 text-center align-middle">{t("aboutus.apptotalgo.detection.static.tool")}</td>
                                    <td className="bg-white p-3">
                                        <ul className="center-ul">
                                            <li>{t("aboutus.apptotalgo.detection.static.result1")}</li>
                                            <li>{t("aboutus.apptotalgo.detection.static.result2")}</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </section>
            <section id="features" className="page-section">
                <div className="container">
                    <div className="text-center">
                        <a href={`/${i18n.language}`} target="_blank" rel="noreferrer"  className="btn btn-lg btn-golden-gradient rounded-pill">{t("aboutus.apptotalgo.detectbtn")}</a>
                    </div>
                </div>
            </section>
            <GoTop />

        </main>
    );
}
export default AboutATG;