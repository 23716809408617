import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useTranslation } from "react-i18next";
import ProgressProvider from "../utils/progressProvider";
import { useEffect, useState } from "react";

function CircleComponent(props) {
  const { t } = useTranslation();
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    setPercent(props.progress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setPercent(props.progress);
  }, [props]);
  const handleSecurityInfoReload = () => {
    props.onHover();
  };

  return (
    <div className="position-relative col-sm-12 col-md-6 col-lg-3">
      <div className="position-relative box circle-box">
        {props.index % 2 === 0 ? (
          <div
            onMouseOver={handleSecurityInfoReload}
            id={props.id}
            className="progress1 "
          >
            <ProgressProvider valueStart={0} valueEnd={percent}>
              {(value) => (
                <CircularProgressbarWithChildren value={value} maxValue={100}>
                  <img src={`/images/${props.id}.png`} alt="" />
                </CircularProgressbarWithChildren>
              )}
            </ProgressProvider>
          </div>
        ) : (
          <div
            onMouseOver={handleSecurityInfoReload}
            id={props.id}
            className="progress2"
          >
            <ProgressProvider valueStart={0} valueEnd={percent}>
              {(value) => (
                <CircularProgressbarWithChildren value={value} maxValue={100}>
                  <img src={`/images/${props.id}.png`} alt="" />
                </CircularProgressbarWithChildren>
              )}
            </ProgressProvider>
          </div>
        )}
      </div>
      <div className="circle-progress-title">
        {t(`home.securityInfo.circleTitle.${props.id}`)}
      </div>
      <div className="position-relative desc-box">
        {props.index % 2 === 0 ? (
          <img src="/images/gold-flag.png" alt="gold-flag" />
        ) : (
          <img src="/images/red-flag.png" alt="red-flag" />
        )}
        <div className="circle-progress-num position-absolute justify-content-center d-flex align-items-center flex-column">
          <span>{percent}%</span>
        </div>
        <div className=" circle-progress-desc position-absolute justify-content-center d-flex align-items-center ">
          <p
            className={`ellipsis5 desc ${
              props.index % 2 === 0 ? "orange-circle" : "yellow-circle"
            }`}
          >
            {t(`home.securityInfo.circleDesc.${props.id}`)}
          </p>
        </div>
      </div>
    </div>
  );
}
export default CircleComponent;
