import React from 'react';
import ReactDOM from 'react-dom';
import './Apptotalgo.css';
import './about.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <App />,
  document.getElementById('AppTotalGo')
);

reportWebVitals();
