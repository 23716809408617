import React from 'react';
import 'chart.js/auto';
import {
    Chart
} from 'react-chartjs-2';

const CustomBarChart = (props) => {
    const { chartData, options, redraw } = props;
    return <Chart
        type='bar'
        data={chartData}
        options={options}
        redraw={redraw} />
}

export default CustomBarChart;